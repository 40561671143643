import apolloUtil from '@/service/ApolloUtil'

import projectVehicleSummaryQuery from '@/service/clockInVehicle/ProjectVehicleSummaryQuery.gql'

class ClockInVehicleService {

  getProjectSummary(params) {
    return apolloUtil.query(projectVehicleSummaryQuery, params)
      .then((response) => response?.data?.projectVehicleSummary)
  }

}

export default new ClockInVehicleService()
