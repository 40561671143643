import apolloUtil from '@/service/ApolloUtil'

import projectsQuery from '@/service/project/ProjectsQuery.gql'
import saveProjectQuery from '@/service/project/SaveProjectMutation.gql'
import saveCategoryProjectMutation from '@/service/project/SaveCategoryProjectMutation.gql'
import deleteProjectQuery from '@/graphql/DeleteProject.gql'
import checkActiveProjectQuery from '@/service/project/CheckActiveProjectQuery.gql'
import deletePartMutation from '@/service/project/DeletePartMutation.gql'
import checkActiveProjectByLabelQuery from '@/service/project/CheckActiveProjectByLabelQuery.gql'
import projectListQuery from '@/service/project/ProjectListQuery.gql'
import getProjectByIdQuery from '@/service/project/GetProjectByIdQuery.gql'

class ProjectService {

  search(params) {
    return apolloUtil.query(projectsQuery, params)
      .then((response) => response?.data?.getProjects)
  }

  save(params) {
    return apolloUtil.mutate(saveProjectQuery, params)
      .then((response) => response?.data?.saveProject)
  }

  saveCategoryProject(params) {
    return apolloUtil.mutate(saveCategoryProjectMutation, params)
      .then((response) => response?.data?.saveCategoryProject)
  }

  delete(params) {
    return apolloUtil.mutate(deleteProjectQuery, params)
  }

  deletePart(params) {
    return apolloUtil.mutate(deletePartMutation, params)
  }

  checkKeyCode(keyCode) {
    return apolloUtil.query(checkActiveProjectQuery, keyCode)
      .then((response) => response?.data?.isProjectActive)
  }

  checkLabel(label) {
    return apolloUtil.query(checkActiveProjectByLabelQuery, label)
      .then((response) => response?.data?.isProjectActiveByLabel)
  }

  searchProjectList(params) {
    return apolloUtil.query(projectListQuery, params)
      .then((response) => response?.data?.projectList)
  }

  getById(params) {
    return apolloUtil.query(getProjectByIdQuery, params)
      .then((response) => response?.data?.getProjectById)
  }

}

export default new ProjectService()
