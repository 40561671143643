import apolloUtil from '@/service/ApolloUtil'

import projectEmployeeSummaryQuery from '@/service/employeeTimeReport/ProjectEmployeeSummaryQuery.gql'

class EmployeeTimeReportService {

  getProjectSummary(params) {
    return apolloUtil.query(projectEmployeeSummaryQuery, params)
      .then((response) => response?.data?.projectEmployeeSummary)
  }

}

export default new EmployeeTimeReportService()
