<template>
  <main class="page-overflow ma-4">
    <!-- page header -->
    <div class="page-header-3 mx-2">
      <!-- page header: filters -->
      <div class="d-flex align-center">
        <!-- project filter -->
        <v-menu offset-y allow-overflow :close-on-click="true" :close-on-content-click="true">
          <template v-slot:activator="{ on }">
            <v-text-field :value="projectIdThrowAway"
                          :placeholder="projectIdThrowAway ? projectIdThrowAway : translations.filterForProject"
                          @input="value => projectFilterHandler({ value, property: 'availableProjectOptions', resetProjectId: true })"
                          @click:clear="projectFilterHandler({ value: '', property: 'availableProjectOptions', resetProjectId: true }), (projectIdThrowAway = ''), (filters.projectId = null)"
                          append-icon="mdi-menu-down" v-on="on" clearable dense hide-details autocomplete="off"
                          style="max-width: 200px" class="mr-4">
            </v-text-field>
          </template>

          <div style="max-height: 300px" class="scroll-bar">
            <v-list dense>
              <v-list-item v-for="(project, index) in availableProjectOptions.projects" :key="`project-${project.id}_${index}`" @click="selectProjectHandler(project)">
                <v-list-item-content>
                  <v-list-item-title>{{ project.keyCode }}</v-list-item-title>
                  <v-list-item-subtitle>{{ project.label }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="!availableProjectOptions.loading && availableProjectOptions.hasNext">
                <div class="d-flex align-center justify-center" style="width: 100%">
                  <v-progress-circular v-intersect.quiet="onProjectIntersect" indeterminate color="main" size="24"></v-progress-circular>
                </div>
              </v-list-item>
            </v-list>
          </div>
        </v-menu>

        <v-select v-if="parts.length > 0"
                  v-model="selectedPart" :items="parts"
                  @change="fetchSummaries"
                  :placeholder="translations.part"
                  clearable dense item-text="keyCode" return-object
                  class="sg-select" hide-details deletable-chips
                  style="max-width: 100px">
          <template v-slot:item="data">
            <span>{{ `${data.item.keyCode} - ${data.item.description}` }}</span>
          </template>
        </v-select>
      </div>

      <!-- page header: title -->
      <div class="all-center">
        <date-header @on-date-change="val => changeDate(val.date)" :value="date" />
      </div>

      <!-- page header: right -->
      <div class="all-center justify-space-around">
        <v-btn icon dark color="main" @click="projectSummaryReportWrap" :disabled="disablePrintBtn">
          <v-icon>mdi-printer</v-icon>
        </v-btn>
      </div>
    </div>

    <v-row class="ma-0">
      <!-- employee summaries table-->
      <v-col cols="7">
        <v-data-table :headers="employeeSummaryHeaders"
                      :items="employeeSummaries" :loading="loading"
                      :items-per-page="-1" fixed-header disable-sort hide-default-footer>
          <template v-slot:no-data>
            <div class="tableSingleSlot">
              {{ projectId ? translations.noDataFound : translations.selectProjectToSeeResults }}
            </div>
          </template>

          <template v-slot:loading>
            <div class="tableSingleSlot">
              {{ translations.loading }}
            </div>
          </template>

          <template v-for="(header, headerIndex) in employeeSummaryHeaders" v-slot:[`item.${header.value}`]="{ item, index }">
            <div v-if="header.value === 'employee'" :key="`emp-${headerIndex}`" style="min-width: 150px; ">
              <component :is="index === employeeSummaries.length - 1 ? 'b' : 'span'">{{ item.employeeName }}</component>
            </div>

            <div v-else-if="header.value === 'ordinaryHours'" :key="`emp-hours-${headerIndex}`" style="text-align: center; min-width: 80px">
              <component :is="index === employeeSummaries.length - 1 ? 'b' : 'span'">{{ item.ordinaryHours }}</component>
            </div>

            <div v-else-if="header.value === 'extraOrdinaryHours'" :key="`emp-extra-${headerIndex}`" style="text-align: center; min-width: 100px">
              <component :is="index === employeeSummaries.length - 1 ? 'b' : 'span'">{{ item.extraOrdinaryHours }}</component>
            </div>

            <div v-else-if="header.value.includes('totalHours')" :key="`emp-total-hours-${headerIndex}`" style="text-align: center; min-width: 100px">
              <component :is="index === employeeSummaries.length - 1 ? 'b' : 'span'">{{ item.ordinaryHours + item.extraOrdinaryHours }}</component>
            </div>
          </template>
        </v-data-table>
      </v-col>

      <!-- vehicle summaries table-->
      <v-col cols="5">
        <v-data-table :headers="vehicleSummaryHeaders"
                      :items="vehicleSummaries" :loading="loading"
                      :items-per-page="-1" fixed-header disable-sort hide-default-footer>
          <template v-slot:no-data>
            <div class="tableSingleSlot">
              {{ projectId ? translations.noDataFound : translations.selectProjectToSeeResults }}
            </div>
          </template>

          <template v-slot:loading>
            <div class="tableSingleSlot">
              {{ translations.loading }}
            </div>
          </template>

          <template v-for="(header, vehicleHeaderIndex) in vehicleSummaryHeaders" v-slot:[`item.${header.value}`]="{ item, index }">
            <div v-if="header.value === 'vehicle'" :key="`emp-${vehicleHeaderIndex}`" style="min-width: 150px; ">
              <span v-if="index < vehicleSummaries.length - 1">{{ item.vehicle.code }} - {{ item.vehicle.label }}</span>
              <b v-else>{{ item.vehicle.code }}</b>
            </div>

            <div v-else-if="header.value === 'hours'" :key="`vehicle-hours-${vehicleHeaderIndex}`" style="text-align: center; min-width: 80px">
              <component :is="index === vehicleSummaries.length - 1 ? 'b' : 'span'">{{ item.hours }}</component>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </main>
</template>


<script>
import { translations } from '@/utils/common'
import { MomentMixin } from '@/utils/mixins/MomentMixin.js'
import debounce from 'lodash/debounce'
import { mapState } from 'vuex'

import { projectSummaryReport } from '@/utils/api/reportsApi'

import projectsQuery from '@/graphql/Projects.gql'

import ProjectService from '@/service/project/ProjectService'
import EmployeeTimeReportService from '@/service/employeeTimeReport/EmployeeTimeReport'
import ClockInVehicleService from '@/service/clockInVehicle/ClockInVehicleService'

import DateHeader from '@/components/DateHeader'

export default {
  name: 'ProjectSummary',

  components: {
    DateHeader
  },

  props: {
    dateProp: {
      type: String,
      default: ''
    },
    projectIdProp: {
      type: Number
    }
  },

  data: () => ({
    loading: false,
    date: null,
    projectId: null,
    projectKeyCode: null,
    selectedPart: {},
    parts: [],
    filters: {
      dateStr: null,
      projectId: null
    },
    availableProjectOptions: {
      projects: [],
      page: 1,
      hasNext: true,
      projectFilter: '',
      loading: false
    },
    projectIdThrowAway: '',
    employeeSummaries: [],
    vehicleSummaries: []
  }),

  async created() {
    if (this.$route?.query?.date) {
      this.date = this.$route.query.date
    } else if (this.dateProp) {
      this.date = this.dateProp
    } else {
      this.date = this.getSubtractedDate(1, 'days').substr(0, 10)
    }

    if (this.$route?.query?.projectId) {
      this.projectId = this.$route?.query?.projectId
    } else if (this.projectIdProp) {
      this.projectId = this.projectIdProp
    }

    // if projectId is present on created we need to get project to set as value for project filter
    if (this.projectId) {
      await this.fetchProjectForDisplay()
    }

    if (this.date && this.projectId) {
      this.fetchSummaries()
    }
  },

  mixins: [MomentMixin],

  methods: {
    changeDate(date) {
      this.date = date
      this.filters.dateStr = this.date
      this.filters.projectId = this.projectId

      // FIXME need to remove if used as component
      const query = { date }
      if (this.projectId) {
        query['projectId'] = this.projectId
      }
      this.$router.push({ name: 'ProjectSummary', query })

      this.fetchSummaries()
    },

    projectFilterHandler: debounce(function(payload) {
      const { value, property } = payload

      if (payload?.resetProjectId) {
        if (!value) {
          this.filters.projectId = null
          this.employeeSummaries = []
          this.vehicleSummaries = []
          this.projectId = null
          this.parts = []
          this.projectKeyCode = null
          this.selectedPart = {}
          this.removeProjectIdQuery()
        }
      }

      this[property].projects = []
      this[property].page = 1
      this[property].hasNext = true
      this[property].projectFilter = value

      this.fetchProjects(property)
    }, 850),

    removeProjectIdQuery() {
      let query = {}
      if (this.date) query['date'] = this.date

      this.$router.push({ name: 'ProjectSummary', query })
    },

    async fetchProjects(property) {
      this[property].loading = true

      try {
        const { page, projectFilter } = this[property]

        const variables = {
          filter: projectFilter,
          fetchNonCategory: true,
          fetchCategory: false,
          activeStatus: true,
          pageSize: 10,
          page: page
        }

        const res = await this.$apollo.query({
          query: projectsQuery,
          variables: variables,
          fetchPolicy: 'network-only'
        })

        const { content, hasNext } = res.data.projects

        this[property].projects.push(...content)
        this[property].hasNext = hasNext
        this[property].page++
      } catch (e) {
        console.log(e)
      }
      this[property].loading = false
    },

    async fetchProjectForDisplay() {
      try {
        const project = await ProjectService.getById({ projectId: this.projectId })
        this.projectKeyCode = project.keyCode
        this.filters.projectId = project.id
        this.projectIdThrowAway = project.keyCode
        this.parts = project.parts
        this.availableProjectOptions.projectFilter = ''
        this.projectFilterHandler({ value: '', property: 'availableProjectOptions', resetProjectId: false })
      } catch (e) {
        console.log(e)
      }
    },

    async fetchSummaries() {
      if (!this.date || !this.projectId) return

      this.loading = true
      await this.fetchEmployeeSummary()
      await this.fetchVehicleSummary()
      this.loading = false
    },

    async fetchEmployeeSummary() {
      try {
        const summaries = await EmployeeTimeReportService.getProjectSummary({
          projectKeyCode: this.projectKeyCode,
          referenceDateStr: this.date,
          partKeyCode: this.selectedPart?.keyCode ? this.selectedPart?.keyCode : ''
        })

        const totalItem = this.setTotalEmployeeItem(summaries)

        summaries.push(totalItem)
        this.employeeSummaries = summaries
      } catch (e) {
        console.log(e)
      }
    },

    setTotalEmployeeItem(list) {
      let ordinaryHours = 0
      let extraOrdinaryHours = 0

      list.forEach(item => {
        ordinaryHours += item.ordinaryHours
        extraOrdinaryHours += item.extraOrdinaryHours
      })

      return { employeeName: translations.total, ordinaryHours, extraOrdinaryHours }
    },

    async fetchVehicleSummary() {
      try {
        const summaries = await ClockInVehicleService.getProjectSummary({
          projectId: this.projectId,
          referenceDateStr: this.date,
          partId: this.selectedPart?.id ? this.selectedPart?.id : null
        })

        const totalItem = this.setTotalVehicleItem(summaries)

        summaries.push(totalItem)
        this.vehicleSummaries = summaries
      } catch (e) {
        console.log(e)
      }
    },

    setTotalVehicleItem(list) {
      let hours = list.reduce((total, item) => total + item.hours, 0)

      return { vehicle: { code: translations.total, label: '' }, hours }
    },

    selectProjectHandler(project) {
      this.projectId = project.id
      this.parts = project.parts
      this.filters.projectId = project.id
      this.projectIdThrowAway = project.keyCode
      this.projectKeyCode = project.keyCode
      this.availableProjectOptions.projectFilter = ''
      this.projectFilterHandler({ value: '', property: 'availableProjectOptions', resetProjectId: false })

      this.$router.push({ name: 'ProjectSummary', query: { ...this.$route.query, projectId: project.id } })

      this.fetchSummaries()
    },

    onProjectIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.fetchProjects('availableProjectOptions')
      }
    },

    projectSummaryReportWrap() {
      projectSummaryReport({
        projectId: this.projectId,
        partId: this.selectedPart?.id ? this.selectedPart.id : '',
        dateFromStr: this.date
      })
    }
  },

  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo
    }),

    translations: () => translations,

    employeeSummaryHeaders() {
      return [
        { text: this.translations.employee, value: 'employee' },
        { text: this.translations.ordinaryHours, value: 'ordinaryHours', align: 'center' },
        { text: this.translations.extraOrdinaryHours, value: 'extraOrdinaryHours', align: 'center' },
        { text: this.translations.totalHours, value: 'totalHours', align: 'center' }
      ]
    },

    vehicleSummaryHeaders() {
      return [
        { text: this.translations.equipment, value: 'vehicle' },
        { text: this.translations.hours, value: 'hours', align: 'center' }
      ]
    },

    disablePrintBtn() {
      return !(this.projectId && this.date && this.employeeSummaries.length > 1)
    }
  }
}
</script>
